import React from 'react';
import '../../App.css';
import CardsOurTeam from '../CardsOurTeam';

export default function OurTeam() {
  return (
    <>
      <h1 className='our-team'>Our Team</h1>
      <CardsOurTeam />
    </>
  );
}
