import React from 'react';
import '../../App.css';
import CardsSession from '../CardsSession';

export default function Sessions() {
  return (
    <>
      <h1 className='sessions'>Sessions</h1>
      <CardsSession />
    </>
  );
}
